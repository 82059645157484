
// Do not edit directly
// Generated on Tue, 03 Oct 2023 09:40:33 GMT

$gradient-new: linear-gradient(90deg, #2f1139 0%, #48285b 100%);
$primary-orange-50: #fff0e5;
$primary-orange-100: #ffd5b7;
$primary-orange-200: #ffbb8a;
$primary-orange-300: #ffa05c;
$primary-orange-400: #ff862e;
$primary-orange-600: #d65a00;
$primary-orange-700: #ad4900;
$primary-orange-800: #853800;
$primary-orange-900: #5c2700;
$primary-orange-p-500: #ff6b00;
$primary-blue-violet-50: #f9fafc;
$primary-blue-violet-100: #eaeef9;
$primary-blue-violet-200: #dbe3f6;
$primary-blue-violet-300: #c0c9dd;
$primary-blue-violet-400: #a6afc3;
$primary-blue-violet-500: #8d96aa;
$primary-blue-violet-600: #757d90;
$primary-blue-violet-700: #5e6577;
$primary-blue-violet-800: #484e5d;
$primary-blue-violet-p-900: #333844;
$secondary-neutral-black-100: #f6f6f6;
$secondary-neutral-black-200: #f1f1f1;
$secondary-neutral-black-300: #e5e5e5;
$secondary-neutral-black-400: #c3c3c3;
$secondary-neutral-black-500: #a5a5a5;
$secondary-neutral-black-600: #7b7b7b; // 70% pure black
$secondary-neutral-black-700: #676767;
$secondary-neutral-black-800: #484848;
$secondary-neutral-black-900: #262626; // equivalent to #000000 87%opacity
$secondary-neutral-black-white: #ffffff;
$secondary-purple-magenta-50: #e8e6ed;
$secondary-purple-magenta-100: #c7c1d3;
$secondary-purple-magenta-200: #a499b5;
$secondary-purple-magenta-300: #827297;
$secondary-purple-magenta-400: #6c5582;
$secondary-purple-magenta-500: #57386e;
$secondary-purple-magenta-600: #513165;
$secondary-purple-magenta-700: #48285b;
$secondary-purple-magenta-800: #3f204f;
$secondary-purple-magenta-p-900: #2f1139;
$semantic-success-50: #f7fff3;
$semantic-success-100: #e6ffd8;
$semantic-success-200: #d4ffbd;
$semantic-success-300: #c3ffa1;
$semantic-success-400: #b2ff86;
$semantic-success-500: #95e369;
$semantic-success-600: #76c14c;
$semantic-success-700: #5b9f34;
$semantic-success-900: #2c5b12;
$semantic-success-p-800: #427d21;
$semantic-safe-50: #eefdff; // Aquamarine
$semantic-safe-100: #d1fbff; // Aquamarine
$semantic-safe-200: #b4f8ff; // Aquamarine
$semantic-safe-300: #97f6ff; // Aquamarine
$semantic-safe-400: #7af3ff; // Aquamarine
$semantic-safe-500: #5df1ff; // Aquamarine
$semantic-safe-600: #3cd0de; // Aquamarine
$semantic-safe-700: #1fa8b5; // Aquamarine
$semantic-safe-900: #005b63; // Aquamarine
$semantic-safe-p-800: #0b818c; // Aquamarine
$semantic-critical-50: #fff8e5;
$semantic-critical-100: #ffedb7;
$semantic-critical-200: #ffe189;
$semantic-critical-300: #ffd65c;
$semantic-critical-400: #ffca2e;
$semantic-critical-600: #d6a000;
$semantic-critical-700: #ad8200;
$semantic-critical-800: #846300;
$semantic-critical-900: #5c4500;
$semantic-critical-p-500: #ffbf00;
$semantic-error-50: #ffece7;
$semantic-error-100: #ffcabe;
$semantic-error-200: #ffa994;
$semantic-error-300: #ff876a;
$semantic-error-400: #ff6640;
$semantic-error-500: #ff4416;
$semantic-error-700: #b42400;
$semantic-error-800: #8b1c00;
$semantic-error-900: #631400;
$semantic-error-p-600: #dd2c00;
$opacity-primary-blue-violet-5: #3338440d;
$opacity-primary-blue-violet-10: #3338441a;
$opacity-primary-blue-violet-20: #33384433;
$opacity-primary-blue-violet-30: #3338444d;
$opacity-primary-blue-violet-40: #33384466;
$opacity-primary-blue-violet-50: #33384480;
$opacity-primary-blue-violet-60: #33384499;
$opacity-primary-blue-violet-70: #333844b3;
$opacity-primary-blue-violet-80: #333844cc;
$opacity-primary-blue-violet-90: #333844e6;
$hover: 0px 2px 8px 5px #ff6b0040;
$box: 0px 0px 16px 16px #484e5d2e;
$box-xs: 0px 0px 4px 1px #7c35021a;
$underbar: 0px 4px 8px 0px #484e5d0d;
$font-families-newjunebold: NewJuneBold;
$font-families-newjuneregular: NewJuneRegular;
$line-heights-0: 130%;
$line-heights-1: 100%;
$font-size-0: 8px;
$font-size-1: 10px;
$font-size-2: 12px;
$font-size-3: 14px;
$font-size-4: 16px;
$font-size-5: 18px;
$font-size-6: 20px;
$font-size-7: 24px;
$letter-spacing-0: 0%;
$paragraph-spacing-0: 0px;
$product-heading-bold-h1: 24px/130% NewJuneBold; // NewJune Bold
$product-heading-bold-h2: 20px/130% NewJuneBold;
$product-heading-bold-h3: 18px/130% NewJuneBold;
$product-heading-bold-h4: 16px/130% NewJuneBold;
$product-heading-bold-h5: 14px/130% NewJuneBold;
$product-heading-bold-h6: 12px/130% NewJuneBold;
$product-heading-bold-underlined-h1: 24px/130% NewJuneBold; // NewJune Bold
$product-heading-bold-underlined-h2: 20px/130% NewJuneBold;
$product-heading-bold-underlined-h3: 18px/130% NewJuneBold;
$product-heading-bold-underlined-h4: 16px/130% NewJuneBold;
$product-heading-bold-underlined-h5: 14px/130% NewJuneBold;
$product-heading-bold-underlined-h6: 12px/130% NewJuneBold;
$product-heading-h1: 24px/130% NewJuneRegular;
$product-heading-h2: 20px/130% NewJuneRegular;
$product-heading-h3: 18px/130% NewJuneRegular;
$product-heading-h4: 16px/130% NewJuneRegular;
$product-heading-h5: 14px/130% NewJuneRegular;
$product-heading-h6: 12px/130% NewJuneRegular;
$product-heading-underlined-h1: 24px/130% NewJuneRegular;
$product-heading-underlined-h2: 20px/130% NewJuneRegular;
$product-heading-underlined-h3: 18px/130% NewJuneRegular;
$product-heading-underlined-h4: 16px/130% NewJuneRegular;
$product-heading-underlined-h5: 14px/130% NewJuneRegular;
$product-heading-underlined-h6: 12px/130% NewJuneRegular;
$product-ui-text-l: 14px/130% NewJuneRegular;
$product-ui-text-m: 12px/130% NewJuneRegular;
$product-ui-text-s: 10px/130% NewJuneRegular; // Captions
$product-ui-text-xs: 8px/130% NewJuneRegular;
$product-ui-text-xxs: 8px/130% NewJuneRegular;
$product-ui-text-xl: 16px/130% NewJuneRegular;
$product-ui-text-bold-l: 14px/130% NewJuneBold;
$product-ui-text-bold-m: 12px/130% NewJuneBold;
$product-ui-text-bold-s: 10px/130% NewJuneBold; // Captions
$product-ui-text-bold-xs: 8px/130% NewJuneBold;
$product-ui-text-bold-xxs: 8px/130% NewJuneBold;
$product-ui-text-bold-xl: 16px/130% NewJuneBold;
$product-ui-text-label-l: 14px/130% NewJuneBold;
$product-ui-text-label-m: 12px/100% NewJuneBold;
$product-ui-text-label-s: 10px/100% NewJuneBold;
$product-ui-text-label-xxl: 20px/100% NewJuneBold;
$product-ui-text-label-xl: 16px/100% NewJuneBold;
$text-case-none: none;
$text-decoration-none: none;
$text-decoration-underline: underline;
$text-decoration-line-through: line-through;
$paragraph-indent-0: 0px;
$border-radius-default: 4px;
$border-radius-xl: 12px;
$orange-leve-1: 0 0 0 0 undefined;
$orange-level-2: 0 0 0 0 undefined;
$orange-level-3: 0 0 0 0 undefined;
$blue-violet-level-1: 0 0 0 0 undefined;
$blue-violet-level-2: 0 0 0 0 undefined;
$blue-violet-level-3: 0 0 0 0 undefined;
$purple-magenta-level-1: 0 0 0 0 undefined;
$purple-magenta-level-2: 0 0 0 0 undefined;
$purple-magenta-level-3: 0 0 0 0 undefined;
$token-set-order-0: global;

$container-size-1: 1680px;
$container-size-2: 1440px;
$container-size-3: 1260px;
$container-size-4: 1030px;
$container-size-5: 990px;
$container-size-6: 900px;
$container-size-7: 850px;
$container-size-8: 700px;
$container-size-9: 575px;
$container-size-10: 480px;
$container-size-11: 320px;
$container-size-12: 250px;
$container-size-13: 170px;