//============================================================
// Mixins
//============================================================
//*/

// REM with px fallback

// See: https://github.com/pierreburel/sass-rem

$rem-baseline: 10px !default;
$rem-fallback: true !default;
$rem-px-only: false !default;

// list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
@function rem-separator($list) {
  @if function-exists('list-separator') == true {
    @return list-separator($list);
  }

  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values) {
  $result: ();
  $separator: rem-separator($values);

  @each $value in $values {
    @if type-of($value) == 'number' and unit($value) == 'rem' and $to == 'px' {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == 'number' and unit($value) == 'px' and $to == 'rem' {
      $result: append($result, calc($value / ($rem-baseline / 1rem)), $separator);
    } @else if type-of($value) == 'list' {
      $result: append($result, rem-convert($to, $value), $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values) {
  @if $rem-px-only {
    @return rem-convert(px, $values);
  } @else {
    @return rem-convert(rem, $values);
  }
}

@mixin rem($properties, $values) {
  @if type-of($properties) == 'map' {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values);
      }
    }
  }
}

@mixin overflow-ellipsis($rows: 1) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;

  @if $rows != 1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: $rows;
    -webkit-line-clamp: $rows;
    white-space: normal;
  }
}
.overflow-ellipsis {
  @include overflow-ellipsis();
}
.overflow-ellipsis-two-rows {
  @include overflow-ellipsis(2);
}

@mixin background-gradient() {
  background: getHeroColor('hero-purple');
  background: -webkit-linear-gradient(
    left,
    getHeroColor('hero-purple'),
    getHeroColor('hero-light-purple')
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    getHeroColor('hero-purple'),
    getHeroColor('hero-light-purple')
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    getHeroColor('hero-purple'),
    getHeroColor('hero-light-purple')
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    to right,
    getHeroColor('hero-purple'),
    getHeroColor('hero-light-purple')
  ); /* Standard syntax */
}

@mixin background-gradient-light-purple() {
  background: getHeroColor('hero-light-purple-1');
  background: -webkit-linear-gradient(
    left,
    getHeroColor('hero-light-purple-1'),
    getHeroColor('hero-light-blue-2')
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    getHeroColor('hero-light-purple-1'),
    getHeroColor('hero-light-blue-2')
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    getHeroColor('hero-light-purple-1'),
    getHeroColor('hero-light-blue-2')
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    to right,
    getHeroColor('hero-light-purple-1'),
    getHeroColor('hero-light-blue-2')
  ); /* Standard syntax */
}
@mixin background-gradient-light-teal() {
  background: getHeroColor('hero-light-teal-1');
  background: -webkit-linear-gradient(
    left,
    getHeroColor('hero-light-teal-1'),
    getHeroColor('hero-light-teal-2')
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    getHeroColor('hero-light-teal-1'),
    getHeroColor('hero-light-teal-2')
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    getHeroColor('hero-light-teal-1'),
    getHeroColor('hero-light-teal-2')
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    to right,
    getHeroColor('hero-light-teal-1'),
    getHeroColor('hero-light-teal-2')
  ); /* Standard syntax */
}

@mixin background-gradient-transparent() {
  background: transparentize(getHeroColor('hero-purple'), 0.05);
  background: -webkit-linear-gradient(
    left,
    transparentize(getHeroColor('hero-purple'), 0.05),
    transparentize(getHeroColor('hero-grey-1'), 0.05)
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    transparentize(getHeroColor('hero-purple'), 0.05),
    gtransparentize(getHeroColor('hero-grey-1'), 0.05)
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    transparentize(getHeroColor('hero-purple'), 0.05),
    gtransparentize(getHeroColor('hero-grey-1'), 0.05)
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    to right,
    transparentize(getHeroColor('hero-purple'), 0.05),
    transparentize(getHeroColor('hero-grey-1'), 0.05)
  ); /* Standard syntax */
}

// Breakpoints

@mixin breakpoint($point) {
  // Standard: mobile first approach, increasing viewport width.
  @if $point == mobile-big {
    @media screen and (min-width: rem($threshold-mobileBig)) {
      @content;
    }
  } @else if $point == phablet {
    @media screen and (min-width: rem($threshold-phablet)) {
      @content;
    }
  } @else if $point == tablet {
    @media screen and (min-width: rem($threshold-tablet)) {
      @content;
    }
  } @else if $point == desktop {
    @media screen and (min-width: rem($threshold-desktop)) {
      @content;
    }
  } @else if $point == desktop-header-break {
    @media screen and (min-width: rem($threshold-desktop-header-flip)) {
      @content;
    }
  } @else if $point == desktop-big {
    @media screen and (min-width: rem($threshold-desktopBig)) {
      @content;
    }
  } @else if $point == mobile-only {
    @media screen and (max-width: rem($threshold-mobileBig - 1)) {
      @content;
    }
  } @else if $point == mobile-big-only {
    @media screen and (min-width: rem($threshold-mobileBig)) and (max-width: rem($threshold-phablet - 1)) {
      @content;
    }
  } @else if $point == phablet-only {
    @media screen and (min-width: rem($threshold-phablet)) and (max-width: rem($threshold-tablet - 1)) {
      @content;
    }
  } @else if $point == tablet-only {
    @media screen and (min-width: rem($threshold-tablet)) and (max-width: rem($threshold-desktop - 1)) {
      @content;
    }
  } @else if $point == desktop-only {
    @media screen and (min-width: rem($threshold-desktop)) and (max-width: rem($threshold-desktopBig - 1)) {
      @content;
    }
  } @else if $point == mobile-landscape {
    @media screen and (orientation: landscape) and (max-width: rem($threshold-tablet)) {
      @content;
    }
  } @else if $point == retina {
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min--moz-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2 / 1),
      only screen and (min-device-pixel-ratio: 2),
      only screen and (min-resolution: 75.6dpcm),
      only screen and (min-resolution: 192dpi),
      only screen and (min-resolution: 2dppx) {
      @content;
    }
  } @else if $point == mobile-not-short {
    @media screen and (min-height: 420px) {
      @content;
    }
  }
}

/** Usage

h1 {
    margin-top: 20px;

    @include breakpoint(tablet) {
        margin-top: 30px;
    }

    @include breakpoint(desktop) {
        margin-top: 40px;
    }
}

*/

// REM Responsive/ Mapped fonts with px fallback
@mixin fontSize($element) {
  $elFontSizes: map-get($fontSizes, $element);

  @if $elFontSizes != null {
    // using tablet sizes as defaults
    font-size: 1px * map_get($elFontSizes, tablet);
    font-size: 0.1rem * map_get($elFontSizes, tablet);

    @include breakpoint(tablet) {
      font-size: 1px * map-get($elFontSizes, tablet);
      font-size: 0.1rem * map-get($elFontSizes, tablet);
    }
    @include breakpoint(desktop) {
      font-size: 1px * map-get($elFontSizes, desktop);
      font-size: 0.1rem * map-get($elFontSizes, desktop);
    }
  } @else {
    @warn 'There is no item "#{$element}" in the fontSize list';
  }
}
/*** Usage

Get mixin settings from font-sizes.scss
and apply to desired element:

h1 {
    @include fontSize(headerTitle);
}

*******/

//Responsive Image

@mixin responsiveImage() {
  max-width: 100%;
  height: auto;
}

//Text truncate - Requires inline-block or block for proper styling

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin tab {
  &.disabled {
    cursor: default;
    a,
    a:hover {
      cursor: default;

      color: #fff;
      border-bottom: none;
      opacity: 0.6;
      span {
        color: #fff;
      }
    }
  }
  &:not(.disabled):hover {
    a {
      .icon.plus {
        background-position-y: -34px;
      }
    }
  }
}

//Remove default styling from a list tag
@mixin list-reset {
  padding: 0;
  margin: 0;
  list-style: none;
}

//Absolute positioning
@mixin absolute-position {
  top: 0;
  left: 0;
  position: absolute;
}

//Fixed positioning
@mixin fixed-position {
  top: 0;
  left: 0;
  position: fixed;
}

//============================================================
// Images
//============================================================

$imgbase: 'https://s3-eu-west-1.amazonaws.com/scopetrackermediastatic/' !default;

@function png($filename) {
  @return url(#{$imgbase}#{$filename});
}

@function png-retina($filename) {
  $retinafilename: str-insert($filename, '@2x', str-index($filename, '.png'));
  @return url(#{$imgbase}#{$retinafilename});
}

@mixin background-png($filename) {
  background-image: png($filename);
  background-repeat: no-repeat;
  background-position: center;
  @include breakpoint(retina) {
    background-image: png-retina($filename);
    // background-size: 16%;
  }
}
/* Background example
    div {
        @include background-png("v2/cancel_red.png");
    }
*/

@mixin listImage-png($filename) {
  list-style-image: png($filename);
}
/* list-style-image example li {
   @include listImage-png("v2/activity-list-past.png");
}
*/

// Elements
@mixin scrollbar() {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $primary-blue-violet-500;
    border: 1px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-track {
    background-color: $primary-blue-violet-100;
  }
}

@mixin scrollbar-x() {
  @include scrollbar();
  overflow-y: none;
  overflow-x: auto;
}

@mixin spinner() {
  .spinner {
    width: 70px;
    text-align: center;
  }

  .spinner > div {
    width: 8px;
    height: 8px;
    background-color: $primary-orange-p-500;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
